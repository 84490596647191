import {
  AUTH_RESPONSE,
  FETCH_DETAILS_RESPONSE,
  FETCH_MESSAGES_RESPONSE,
  USER_DETAILS_RESPONSE,
  GET_IP_RESPONSE,
} from './chat.types'
/**
 * @fileOverview Manages the response of action
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example switch (action.type) {
    case TYPE_NAME: {
      return {
        ...state,
        ...{INITIAL_STATE: action.payload },
      }
    }
    }
 */

const INITIAL_STATE = {
  authResponse: null,
  userDetails: null,
  messages: null,
  patientInformation: null,
  ipAddress: null,
}

/**
 *
 * @param state - global state management
 * @param action - contains type and payload
 * @returns {{loginResponse: {}, login: boolean, status: boolean}|{loginResponse: *, login: boolean, status: boolean}|{loginResponse: {}, login: boolean, status: *}}
 */

const chatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_RESPONSE: {
      return { ...state, ...{ login: true, authResponse: action.payload } }
    }
    case FETCH_DETAILS_RESPONSE: {
      return { ...state, ...{ login: true, userDetails: action.payload } }
    }
    case FETCH_MESSAGES_RESPONSE: {
      return { ...state, ...{ login: true, messages: action.payload } }
    }
    case USER_DETAILS_RESPONSE: {
      return {
        ...state,
        ...{ login: true, patientInformation: action.payload },
      }
    }
    case GET_IP_RESPONSE: {
      return { ...state, ...{ login: true, ipAddress: action.payload } }
    }
    default:
      return state
  }
}
export default chatReducer
